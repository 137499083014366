import { request } from "./network";

// 获取普通组、特殊组数据 （普通-common 特殊-special）
export function getAllDataByGroupType(params) {
    return request({
        method: "get",
        url: "/eventProblem/getAllDataByGroupType",
        params,
    });
}
